<template>
	<div class="container">
		<el-form :inline="true">
			<el-form-item>
				<el-button type="primary" @click="showDrawer">添加属性</el-button>
			</el-form-item>
		</el-form>
		<el-table :data="modelAttributes" style="width: 100%" border>
			<el-table-column prop="attribute" label="属性" width="180px" />
			<el-table-column prop="default" label="默认值" />
			<el-table-column prop="time" label="修改时间" width="180px">
				<template #default="scope">
					{{timeToDate(scope.row.time)}}
				</template>
			</el-table-column>
			<el-table-column label="操作" width="180px">
				<template v-slot="scope">
					<el-button type="primary" size="mini" @click="openUpdateDefaultDialog(scope.row.attribute,scope.row.default)">修改默认值</el-button>
				</template>
			</el-table-column>
		</el-table>

	</div>


	<el-drawer v-model="drawer" :lock-scroll="false" title="添加属性" :close-on-press-escape="false" @close="closeDrawer" size="70%" :close-on-click-modal="false">
		<el-steps :space="200" :active="steps" align-center space="50%">
			<el-step title="选择属性"></el-step>
			<el-step title="修改默认值"></el-step>
		</el-steps>
		<template v-if="steps == 1">
			<el-table :data="attributes" ref="attributeTable" @selection-change="selectionChange">
				<el-table-column type="selection" width="55px"></el-table-column>
				<el-table-column property="identifier" label="属性" width="120px"></el-table-column>
				<el-table-column property="desc" label="说明" width="220px"></el-table-column>
				<el-table-column property="data_type" label="数据类型" width="120px"></el-table-column>
				<el-table-column property="default" label="默认值" width="700px">
					<template #default="scope">
						{{scope.row.default}}
					</template>
				</el-table-column>
				<el-table-column type="desc"></el-table-column>
			</el-table>
		</template>


		<template v-if="steps == 2">
			<el-form class="demo-form-inline" label-width="120px">
				<el-form-item v-for="(value, key) in selectedAttributes" :label="selectedAttributes[key].identifier">
					<el-input v-model="selectedAttributes[key].default" />
				</el-form-item>
			</el-form>
		</template>



		<div class="drawer_footer">
			<el-button @click="closeDrawer">取消</el-button>
			<el-button v-if="steps == 1" type="primary" @click="nextButton">下一步</el-button>
			<el-button v-else type="primary" @click="submitAddAttribute">提交</el-button>
		</div>
	</el-drawer>


	<el-dialog v-model="showUpdateDialog" :title="'修改 '+updateForm.attribute" width="30%" :before-close="handleClose">
		<el-form label-width="70px">
			<el-form-item label="属性">
				<el-input disabled v-model="updateForm.attribute" />
			</el-form-item>
			<el-form-item label="默认值">
				<el-input v-model="updateForm.value" :rows="2" type="textarea" />
			</el-form-item>
		</el-form>
		<template #footer>
			<span class="dialog-footer">
				<el-button @click="showUpdateDialog = false">Cancel</el-button>
				<el-button type="primary" @click="submitUpdateAttribute">Confirm</el-button>
			</span>
		</template>
	</el-dialog>
</template>

<script>
	export default {
		name: 'ModelAttribute',
		mixins: [],
		components: {},
		props: {},
		data() {
			return {
				searchForm: {
					model: "",
				},
				assignProperties: {
					model: "",
					attributes: {},
				},
				modelAttributes: [],
				attributes: [],
				selectedAttributes: [],
				drawer: false,
				steps: 1,
				updateForm: {
					model: "",
					attribute: "",
					value: "",
				},
				showUpdateDialog: false,
			}
		},
		mounted() {
			this.searchForm.model = this.$route.query.model;
			this.assignProperties.model = this.$route.query.model;
			this.updateForm.model = this.$route.query.model;
			this.ProductModelAttributes()
		},
		computed: {},
		watch: {},
		methods: {
			ProductModelAttributes: function() {
				this.api.ProductModelAttributes(this.searchForm).then(res => {
					if (res.data.code == 200) {
						this.modelAttributes = res.data.data;
						return;
					}
					this.$message.error("获取型号列表失败")
				});
			},
			GetAllAttriubte: function() {
				this.api.TslAttributeList({
					rows: 2000
				}).then(res => {
					this.attributes = res.data.data.data;
					this.$nextTick(() => this.selectedDefualt())
				})
			},
			selectedDefualt: function() {
				this.attributes.forEach(row => {
					console.info(row)
					if (row.required == 1 || this.InModelAttribute(row.identifier)) {
						this.$refs.attributeTable.toggleRowSelection(row, true);
						if (row.default instanceof Object) {
							row.default = JSON.stringify(row.default);
						}
						this.selectedAttributes[row.identifier] = row;
					}
				});
			},
			InModelAttribute: function(attribute) {
				if (!this.modelAttributes) {
					return false;
				}
				for (let value of this.modelAttributes) {
					if (value.attribute == attribute) {
						return true;
					}
				}
				return false;
			},
			showDrawer: function() {
				this.GetAllAttriubte()
				this.drawer = true;
			},
			selectionChange: function(selection) {
				this.selectedAttributes = {};
				for (let row of selection) {
					if (row.default instanceof Object) {
						row.default = JSON.stringify(row.default);
					}
					this.selectedAttributes[row.identifier] = row;
					// this.selectedAttributes.push(row);
				}
			},
			nextButton: function() {
				if (this.steps == 1) {
					this.steps += 1
				}
				var newArr = [];
				for (let key in this.selectedAttributes) {
					newArr.push(this.selectedAttributes[key])
				}
				this.selectedAttributes = newArr;
			},
			closeDrawer: function() {
				this.steps = 1;
				this.selectedAttributes = {};
				this.drawer = false;
			},
			submitAddAttribute: function() {
				for (let key in this.selectedAttributes) {
					let item = this.selectedAttributes[key];
					this.assignProperties.attributes[item.identifier] = item.default;
					if (item.data_type == "array" || item.data_type == "struct") {
						this.assignProperties.attributes[item.identifier] = JSON.parse(item.default);
					}
				}

				this.api.ProductModelAttributesAssign(this.assignProperties).then(res => {
					if (res.data.code == 200) {
						this.$message.success("提交成功")
						this.closeDrawer()
						this.ProductModelAttributes()
						return;
					}
					this.$message.error(res.data.msg)
				})
			},
			openUpdateDefaultDialog: function(attribute, value) {
				this.showUpdateDialog = true;
				this.updateForm.attribute = attribute;
				this.updateForm.value = value;
			},
			submitUpdateAttribute: function() {
				this.api.ProductModelAttributesDefault(this.updateForm).then(res => {
					if(res.data.code == 200){
						this.$message.success("提交成功")
						this.showUpdateDialog = false;
						this.ProductModelAttributes()
						return
					}
					this.$message.error(res.data.msg)
				})
			}
		}
	}
</script>

<style>
	.el-drawer__body {
		padding: 10px;
		overflow: auto;
	}

	.drawer_footer {
		padding: 10px;
	}
</style>